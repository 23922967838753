import React from "react";
import Chart from "react-apexcharts";

const CircularChart = ({title, podcaster, guest, label}) => {

  const series = [podcaster, guest];

  const options = {
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(1) + "%"
      },
    },
    labels:label,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
          },
        },
      },
    },
    legend: {
      position: "bottom",
      offsetX: 0,
      height: 30,
    },
  };

  return (
    <div className=" w-full h-full rounded-lg bg-white space-y-3 sm:space-y-4 p-3 sm:p-4">
       <p className="font-semibold text-base sm:text-lg">{title}</p>
      <Chart width={'100%'} options={options} series={series} height="350" type="donut" />
    </div>
  );
};

export default CircularChart;