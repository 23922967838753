import React from "react";
import { Link, useLocation } from "react-router-dom";
import { BiHome, BiLogOutCircle } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import { useSelector } from "react-redux";
import { RiAdminLine } from "react-icons/ri";
import { TbBrandApplePodcast } from "react-icons/tb";
import { TbUsersGroup } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../../assets/png/logo.png";
import { VscGistSecret } from "react-icons/vsc";

const SideNav = ({ isNav, setisNav }) => {
  const { pathname, search } = useLocation();
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleLogout() {
    dispatch({ type: "LOGOUT" });
    navigate("/");
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setisNav(!isNav);
      }}
      className={`fixed z-[37] inset-y-0 left-0 h-full let swipeInLeft ${
        isNav
          ? "w-full bg-black bg-opacity-50 min-[1024px]:w-[250px]"
          : "max-[1024px]:hidden w-[250px] "
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="px-4 py-3 sm:px-6 sm:py-4 flex flex-col relative items-center h-full w-[250px] cursor-pointer shadow-md bg-white"
      >
        <div className="my-1 justify-center  flex flex-col w-full ">
          <Link
            to="/"
            className=" my-3 h-[64px] sm:h-[40px] w-[120px] sm:w-[80px]"
          >
            <img src={logo} alt="" className="w-full h-full" />
          </Link>

          <Link
            to="/dashboard"
            className={` flex space-x-3 justify-start items-center relative font-medium px-3 my-2 py-2 ${
              pathname.toLocaleLowerCase().includes("/dashboard")
                ? "bg-[#bbf1ed] text-[#00CCBB]  rounded-lg "
                : "text-zinc-700"
            } `}
          >
            <BiHome className="text-[25px] " />
            <span>Home</span>
            {pathname.toLocaleLowerCase().includes("/dashboard") && (
              <span className="rounded-r-lg w-2 h-full bg-[#00CCBB] absolute left-[-36px]"></span>
            )}
          </Link>
          <Link
            to={`/users?type=podcaster`}
            className={`flex space-x-3 justify-start items-center relative font-medium px-3 my-2 py-2 ${
              search.includes("podcaster")
                ? "bg-[#bbf1ed] text-[#00CCBB]  rounded-lg"
                : "text-zinc-700"
            } `}
          >
            <TbBrandApplePodcast className="text-[25px] " />
            <span>Podcasters</span>
            {search.includes("podcaster") && (
              <span className="rounded-r-lg w-2 h-full bg-[#00CCBB] absolute left-[-36px]"></span>
            )}
          </Link>

          <Link
            to={`/users?type=guest`}
            className={`flex space-x-3 justify-start items-center relative font-medium px-3 my-2 py-2 ${
              search.includes("guest")
                ? "bg-[#bbf1ed] text-[#00CCBB]  rounded-lg"
                : "text-zinc-700"
            } `}
          >
            <AiOutlineUser className="text-[25px] " />
            <span>Guests</span>
            {search.includes("guest") && (
              <span className="rounded-r-lg w-2 h-full bg-[#00CCBB] absolute left-[-36px]"></span>
            )}
          </Link>

          <Link
            to={`/users?role=member`}
            className={`flex space-x-3 justify-start items-center relative font-medium px-3 my-2 py-2 ${
              search.includes("member")
                ? "bg-[#bbf1ed] text-[#00CCBB]  rounded-lg"
                : "text-zinc-700"
            } `}
          >
            <TbUsersGroup className="text-[25px] " />
            <span>Users</span>
            {search.includes("member") && (
              <span className="rounded-r-lg w-2 h-full bg-[#00CCBB] absolute left-[-36px]"></span>
            )}
          </Link>
          <Link
            to={`/users?role=admin`}
            className={`flex space-x-3 justify-start items-center relative font-medium px-3 my-2 py-2 ${
              search.includes("admin")
                ? "bg-[#bbf1ed] text-[#00CCBB]  rounded-lg"
                : "text-zinc-700"
            } `}
          >
            <RiAdminLine className="text-[25px] " />
            <span>Admin</span>
            {search.includes("admin") && (
              <span className="rounded-r-lg w-2 h-full bg-[#00CCBB] absolute left-[-36px]"></span>
            )}
          </Link>
          <Link
            to={`/secret-signup`}
            className={`flex space-x-3 justify-start items-center relative font-medium px-3 my-2 py-2 ${
              pathname.includes("secret-signup")
                ? "bg-[#bbf1ed] text-[#00CCBB]  rounded-lg"
                : "text-zinc-700"
            } `}
          >
            <VscGistSecret className="text-[25px] " />
            <span>Secret Signup</span>
            {pathname.includes("secret-signup") && (
              <span className="rounded-r-lg w-2 h-full bg-[#00CCBB] absolute left-[-36px]"></span>
            )}
          </Link>
          <div
            onClick={handleLogout}
            className={`relative flex mt-[20%] space-x-3 justify-start items-center px-3 py-2 font-medium bg-red-600 bg-opacity-[0.15] rounded-lg text-red-500`}
          >
            <BiLogOutCircle className="text-[25px] " />
            <span>Log out</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
