import { useNavigate } from "react-router-dom";
import Container from "../admincontainer/container";
import { MdNavigateBefore } from "react-icons/md";
import { LuLoader2 } from "react-icons/lu";
import empty from "../../assets/png/emptyorder.png";
import { useEffect, useState } from "react";
import SecretCodeWidget from "../recordwidget/secretCodeWidget";
import { useSelector } from "react-redux";
import axios from "../../Utils/useAxios";
import { LoaderIcon } from "lucide-react";
import toast from "react-hot-toast";

export default function SecretSignUp() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  async function genCode() {
    setLoader(true);
    axios
      .get(`admin/gen-code`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        toast.success("Code Generated  ");
        fetchData();
        setLoader(false);
      })
      .catch((err) => {
        toast.error("Unable to generated code");
      })
      .finally(() => {
        setLoader(false);
      });
  }

  async function fetchData() {
    setLoading(true);
    axios
      .get(`admin/secret-codes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
      //  console.log(res?.data)
        
        setData(res?.data);
      })
      .catch((er) => {
        console.log(er);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Container>
      <div className="relative w-full h-fit px-2  sm:px-6  pt-4 sm:pt-16">
        <div
          onClick={() => {
            navigate(-1);
          }}
          className="absolute top-3 left-3 cursor-pointer w-fit h-fit flex space-x-2 items-center"
        >
          <MdNavigateBefore className="text-[22px]" />
          Back
        </div>

        <div className="flex items-end justify-end w-full">
          <button
            onClick={genCode}
            className="w-48 text-white gap-x-2 flex items-center justify-center bg-[#00CCBB] rounded-md h-12  px-3"
          >
            {loader && <LuLoader2 className="text-[22px] animate-spin" />}
            <p> Secret Code</p>
          </button>
        </div>

        <h2 className="font-semibold text-lg sm:text-2xl mb-3 sm:mb-5">
          Secret Codes
        </h2>
        <div className="dashboard-scroll-style w-full h-fit overflow-x-auto">
          <div className="w-full min-w-[1200px] bg-white rounded-lg pb-4 space-y-3">
            <div className="w-full items-center bg-gray-200 px-3 py-3 sm:py-5 grid grid-cols-4 gap-4 text-gray-500">
              <div className="flex space-x-2 items-center">
                <span>CreatedAt</span>
              </div>
              <div className="col-span-1">Code</div>

              <div className="col-span-2">Secret Link</div>
            </div>
            {loading && (
              <div className="w-full items-center justify-center flex h-[300px]">
                <div className="justify-center flex w-fit h-fit items-center">
                  <LoaderIcon className="w-10 animate-spin text-purple-700" />
                </div>
              </div>
            )}
            {!loading && data?.length === 0 && (
              <div className="w-full h-[300px] flex justify-center items-center">
                <span className="w-[200px] h-[200px]">
                  <img className="w-full h-full" src={empty} alt="" />
                </span>
              </div>
            )}

            {!loading &&
              Array.isArray(data) &&
              data?.map(({ code, created_at }, idx) => (
                <SecretCodeWidget
                  key={idx}
                  code={code}
                  createdAt={created_at}
                />
              ))}
          </div>
        </div>
      </div>
    </Container>
  );
}
