import React from "react";

function Inventories({ title, subtitle }) {
  return (
    <div className="rounded-[10px] flex flex-col w-full justify-start items-start inventory p-[20px] bg-white">
      <div className="flex flex-col gap-y-2 items-start gap-2">
      <h1 className="text-sm font-light"> {title} </h1>
      <p className="text-lg sm:text-xl font-bold"> {subtitle} </p>
      </div>
    
    </div>
  );
}

export default Inventories;
