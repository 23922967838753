import React, { useEffect } from "react";
import Inventories from "../card/inventories";
import UserChart from "../Composable/userchat";
import Inventorychat from "../Composable/inventory";
import User from "../Composable/user";
import Container from "../admincontainer/container";
import { useSelector } from "react-redux";
import { useState } from "react";
import published from "../../assets/svg/published.svg";
import sales from "../../assets/svg/sales.svg";
import users from "../../assets/svg/users.svg";
import axios from "../../Utils/useAxios";
import CircularChart from "../card/circlularChart";

function AdminDashboard() {
  const { token } = useSelector((state) => state.user);
  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetchMetric() {
      await axios
        .get(`/admin/metrics`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setData(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchMetric();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const payload = {
    totalUsers: 36,
    completedSubscriptions: 0,
    activeSubscriptions: 11,
    expiredSubscriptions: 0,
    podcasters: 6,
    guests: 3,
    male: 7,
    female: 2,
    guestsPercentage: "8.3333",
    podcastersPercentage: "16.6667",
    malePercentage: "19.4444",
    femalePercentage: "5.5556",
  };
  return (
    <Container>
      <div className="w-full h-fit px-2  sm:px-6 bg-white pt-4 sm:pt-16">
        <div className=" mb-10 grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1  sm:grid-cols-2 gap-10">
          <Inventories title="Total Users" subtitle={data?.totalUsers ?? 0} />
          <Inventories
            title="Total Podcasters"
            subtitle={data?.podcasters ?? ""}
          />
          <Inventories title="Total Guests" subtitle={data?.guests ?? 0} />
          <Inventories
            title="Total Accounts"
            subtitle={data?.totalAccounts ?? 0}
          />
          <Inventories title="Total Admins" subtitle={data?.totalAdmins ?? 0} />
         
          {/**
           <Inventories
            title="Completed Subscriptions"
            subtitle={data?.completedSubscriptions ?? 0}
          />
           <Inventories
            title="Active Subscriptions"
            subtitle={data?.activeSubscriptions ?? 0}
          />
          <Inventories
            title="Expired Subscriptions"
            subtitle={data?.expiredSubscriptions ?? 0}
          />
          */}
        </div>

        <div className="grid w-full md:grid-cols-2 gap-6 mt-5">
          <CircularChart
            label={["Podcasters", "Guests"]}
            guest={Number(data?.guests) || 0}
            title={"Podcasters Vs Guests"}
            podcaster={Number(data?.podcasters) || 0}
          />
          <CircularChart
            label={["Female", "Male"]}
            guest={Number(data?.male) || 0}
            title={"Genders"}
            podcaster={Number(data?.female) || 0}
          />
        </div>
      </div>
    </Container>
  );
}

export default AdminDashboard;
