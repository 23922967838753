import React, { useState } from "react";
import { FiCopy } from "react-icons/fi";
import copy from "copy-to-clipboard";
import { IoTriangle } from "react-icons/io5";
const SecretCodeWidget = ({ code, createdAt }) => {
  const [isShow, setShow] = useState(false);
  const [isShowCode, setShowCode] = useState(false)

  function djCopied() {
    copy(secretLink);
    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 3000);
  }

  function djCoppied() {
    copy(code);
    setShowCode(true);

    setTimeout(() => {
        setShowCode(false);
    }, 3000);
  }

  const secretLink = `https://app.thepodguestlist.com/secret-sign-up/${code}`;
  return (
    <>
      <div className="w-full items-center bg-white border-b px-3 py-3 sm:py-5 grid grid-cols-4 gap-4 ">
        <div className="flex flex-col items-start justify-start">
          <p>{createdAt?.split("T")[0]}</p>
          <p>{createdAt.split("T")[1]}</p>
        </div>
        <div className="w-full flex items-center gap-x-2">
          <p className="text-ellipsis whitespace-nowrap overflow-hidden  w-full">
            {code}
          </p>
          <button className="relative " onClick={djCoppied} title="copy">
            <FiCopy className="text-[22px]" />

            {isShowCode && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="absolute shadow-lg border top-[-4rem] bg-opacity-40 flex items-center justify-center w-fit px-4 py-2 px- -right-6 bg-white rounded-lg"
              >
                <p className="text-background">Copied</p>

                <div className="absolute right-[38%] bottom-[-16px]">
                  <IoTriangle className="rotate-180 text-white  text-[22px]" />
                </div>
              </div>
            )}
          </button>
        </div>

        <div className="w-full flex items-center gap-x-2">
          <p className="w-full text-ellipsis whitespace-nowrap overflow-hidden">
            {secretLink}
          </p>
          <button className="relative " onClick={djCopied} title="copy">
            <FiCopy className="text-[22px]" />

            {isShow && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="absolute shadow-lg border top-[-4rem] bg-opacity-40 flex items-center justify-center w-fit px-4 py-2 px- -right-6 bg-white rounded-lg"
              >
                <p className="text-background">Copied</p>

                <div className="absolute right-[38%] bottom-[-16px]">
                  <IoTriangle className="rotate-180 text-white  text-[22px]" />
                </div>
              </div>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default SecretCodeWidget;
