import React, { useState } from "react";
import profile from "../../assets/png/customerpic.png";
import { MdDelete } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { RiAdminLine } from "react-icons/ri";
import { DeleteCard } from "../Composable/deleteModal";
import { ChangeCard } from "../Composable/StateChangeModal";
const PodcasterWidget = ({
  image,
  name,
  status,
  isRole,
  role,
  createdAt,
  email,
  subscribed,
  id,
  refetch,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [isChange, setChange] = useState(false);

  const Icon = role === "member" ? RiAdminLine : AiOutlineUser;
  function onClose() {
    setOpen((prev) => !prev);
  }
  function onOpen() {
    setChange((prev) => !prev);
  }
  return (
    <>
      <div className="w-full items-center bg-white border-b px-3 py-3 sm:py-5 grid grid-cols-8 gap-4 ">
        <div className="w-full col-span-2 grid grid-cols-5">
          <div className="w-[40px] h-[40px] rounded-full">
            <img
              src={image || profile}
              alt=""
              className=" w-full h-full rounded-full"
            />
          </div>
          <p className="col-span-4">{name}</p>
        </div>
        <p className="line-clamp-1 col-span-2 w-full">{email}</p>
        {status === 1 ? (
          <p className="text-green-400 px-2 flex items-center justify-center  text-xs rounded-md">
            Active
          </p>
        ) : (
          <p className="text-red-400 px-2 flex items-center justify-center text-xs rounded-md">
            Inactive
          </p>
        )}

        <div className="flex flex-col items-start justify-start">
          <p>{createdAt?.split("T")[0]}</p>
          <p>{createdAt.split("T")[1]}</p>
        </div>

    

        <div className="flex items-center gap-x-2">
          <button
            title="Delete"
            onClick={onClose}
            className="flex items-center justify-center bg-gray-200 rounded-full h-9 w-9"
          >
            <MdDelete className="text-[22px]" />
          </button>
          {isRole && (
            <button
              onClick={onOpen}
              title={role === "admin" ? "Make User" : "Make Admin"}
              className="flex items-center justify-center bg-gray-200 rounded-full h-9 w-9"
            >
              <Icon className="text-[22px]" />
            </button>
          )}
        </div>
      </div>
      {isOpen && <DeleteCard refetch={refetch} id={id} close={onClose} />}
      {isChange && (
        <ChangeCard refetch={refetch} id={id} role={role} close={onOpen} />
      )}
    </>
  );
};

export default PodcasterWidget;
