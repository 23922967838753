import toast, { LoaderIcon } from "react-hot-toast";
import { nanoid } from "nanoid";
import { useState } from "react";
import axios from "../../Utils/useAxios";
import { useSelector } from "react-redux";
export function CreateUserCard({ close }) {
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [createUserData, setCreateUserData] = useState({
    fullname: "",
    email: "",
    registerCode: "",
  });
  const [isCreated, setCreated] = useState(false);
  const [gendPassword, setgenPassword] = useState("");

  async function create() {
    const randomPassword = nanoid(8);
    setgenPassword(randomPassword);
    setLoading(true);
    const payload = {
      ...createUserData,
      password: randomPassword,
      password_confirmation: randomPassword,
    };
    axios
      .post(`/register`, payload)
      .then((res) => {
        toast.success("User created successfully");
        setLoading(false);
        setCreateUserData({
          fullname: "",
          email: "",
          registerCode: "",
        });
        setCreated(true);
      })
      .catch((err) => {
        setLoading(false);
      });
  }
  return (
    <div
      onClick={close}
      className="w-full h-full z-[200] inset-0 fixed bg-black/50"
    >
      <form
        onClick={(e) => {
          e.stopPropagation();
        }}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          create();
        }}
        className="w-[95%] sm:w-[500px] rounded-md px-4 bg-white py-6 gap-y-4 flex flex-col items-start justify-start  shadow-md m-auto absolute inset-0 h-fit"
      >
        <h2 className="w-full mb-6 font-semibold text-xl ">Create Account</h2>
        <div className="form-group space-y-4 w-full">
          <label className="block font-semibold " htmlFor="password">
            Full Name
          </label>
          <input
            className="block form__input border-gray-200 border focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-full h-12 px-4"
            type="text"
            placeholder="john doe"
            name="name"
            value={createUserData.fullname}
            onChange={(e) => {
              setCreateUserData({
                ...createUserData,
                fullname: e.target.value,
              });
            }}
          />
        </div>
        <div className="form-group space-y-4 w-full">
          <label className="block font-semibold " htmlFor="email">
            Email Address
          </label>
          <input
            className="block form__input border border-gray-200 focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-full h-12 px-4"
            type="email"
            placeholder="name@company.com"
            name="email"
            value={createUserData.email}
            onChange={(e) => {
              setCreateUserData({ ...createUserData, email: e.target.value });
            }}
          />
        </div>
        <div className="form-group space-y-4 w-full">
          <label className="block font-semibold " htmlFor="email">
            Registration Code
          </label>
          <input
            className="block form__input border border-gray-200 focus:border-gray-500 hover:border-gray-500 rounded-md focus:outline-none w-full h-12 px-4"
            type="text"
            placeholder="wwegsdsdfs"
            name="registerCode"
            value={createUserData.registerCode}
            onChange={(e) => {
              setCreateUserData({
                ...createUserData,
                registerCode: e.target.value,
              });
            }}
          />
        </div>

        <button
          type="submit"
          className="bg-[#00CCBB] h-12 w-full flex items-center justify-center gap-x-2 text-white font-medium rounded-md"
        >
          {loading && <LoaderIcon className="animate-spin" />}
          <span> Create Account</span>
        </button>

        {isCreated && <p>{`User Password: ${gendPassword}`}</p>}
      </form>
    </div>
  );
}
