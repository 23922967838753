import React, { useEffect, useState } from "react";
import axios from "../../Utils/useAxios";
import { useLocation, useNavigate } from "react-router-dom";
import { MdNavigateBefore } from "react-icons/md";
import { useSelector } from "react-redux";
import Container from "../admincontainer/container";
import { LoaderIcon } from "lucide-react";
import PodcasterWidget from "../recordwidget/recordWidget";
import empty from "../../assets/png/emptyorder.png";
import { CreateUserCard } from "../manageaccount/createUser";
export default function Podcasters() {
  const { token } = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [data, setdata] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen((prev) => !prev);
  }

  async function fetchData() {
    axios
      .get(
        `admin/users?page=${page}&type=${
          search.includes("type") ? search.replace("?type=", "") : ""
        }&role=${search.includes("role") ? search.replace("?role=", "") : ""}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setloading(false);
        const { items, totalItems, totalPage, page } = res.data;
        setdata(items);
        const pageNumbers = [...Array(totalPage).keys()].map(
          (page) => page + 1
        );
        setPage(page);

        setTotalItems(pageNumbers);
      })
      .catch((er) => {
        console.log(er);
      });
  }
  useEffect(() => {
    fetchData();
  }, [page, search]);

  return (
    <Container>
      <div className="relative w-full h-fit px-2  sm:px-6  pt-4 sm:pt-16">
        <div
          onClick={() => {
            navigate(-1);
          }}
          className="absolute top-3 left-3 cursor-pointer w-fit h-fit flex space-x-2 items-center"
        >
          <MdNavigateBefore className="text-[22px]" />
          Back
        </div>

        {!search.includes("admin") && (
          <div className="flex items-end justify-end w-full">
            <button
              onClick={onClose}
              className="text-white bg-[#00CCBB] rounded-md h-12 w-fit px-3"
            >
              Create Account
            </button>
          </div>
        )}
        <h2 className="font-semibold text-lg sm:text-2xl mb-3 sm:mb-5">
          Users List
        </h2>
        <div className="dashboard-scroll-style w-full h-fit overflow-x-auto">
          <div className="w-full min-w-[1200px] bg-white rounded-lg pb-4 space-y-3">
            <div className="w-full items-center bg-gray-200 px-3 py-3 sm:py-5 grid grid-cols-8 gap-4 text-gray-500">
              <div className="flex space-x-2 items-center col-span-2">
                <span>Name</span>
              </div>
              <div className="col-span-2">Email</div>
              <div className="">Status</div>
              <div>CreatedAt</div>
            

              <div>Action</div>
            </div>
            {loading && (
              <div className="w-full items-center justify-center flex h-[300px]">
                <div className="justify-center flex w-fit h-fit items-center">
                  <LoaderIcon className="w-10 animate-spin text-purple-700" />
                </div>
              </div>
            )}
            {!loading && data?.length === 0 && (
              <div className="w-full h-[300px] flex justify-center items-center">
                <span className="w-[200px] h-[200px]">
                  <img className="w-full h-full" src={empty} alt="" />
                </span>
              </div>
            )}

            {!loading &&
              Array.isArray(data) &&
              data?.map(
                (
                  {
                    first_name,
                    avatar,
                    id,
                    status,
                    created_at,
                    email,
                    subscribed,
                  },
                  idx
                ) => (
                  <PodcasterWidget
                    key={idx}
                    subscribed={subscribed}
                    name={first_name}
                    image={avatar}
                    status={status}
                    createdAt={created_at}
                    email={email}
                    id={id}
                    isRole={search.includes("role")}
                    role={search.replace("?role=", "")}
                    refetch={fetchData}
                  />
                )
              )}
          </div>
        </div>
      </div>
      {totalItems && (
        <div className="mt-8 w-full">
          <div className="flex justify-center space-x-1 items-center">
            {totalItems?.map((pagenumber, idx) => {
              return (
                <button
                  onClick={() => {
                    setPage(pagenumber);
                  }}
                  key={idx}
                  className={`hover:bg-foreground text-white hover:text-background w-fit rounded-lg h-[30px] px-3 ${
                    page === pagenumber ? "bg-pink-600" : "bg-[#0449a4]"
                  }`}
                >
                  {pagenumber}
                </button>
              );
            })}
          </div>
        </div>
      )}

      {isOpen && <CreateUserCard close={onClose} />}
    </Container>
  );
}
