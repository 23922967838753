import { AiOutlineUser } from "react-icons/ai";
import { RiAdminLine } from "react-icons/ri";
import toast, { LoaderIcon } from "react-hot-toast";
import { useState } from "react";
import axios from "../../Utils/useAxios";
import { useSelector } from "react-redux";
export function ChangeCard({ id, close, role, refetch }) {
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.user);
  const Icon = role === "member" ? RiAdminLine : AiOutlineUser;

  async function changeType() {
    setLoading(true);
    axios
      .post(
        `/admin/change-role`,
        { role: role === "member" ? "admin" : "member", user: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success("Role Updated");
        setLoading(false);
        refetch();
        close();
      })
      .catch((err) => {
        setLoading(false);
      });
  }
  return (
    <div
      onClick={close}
      className="w-full h-full z-[500] inset-0 fixed bg-black/50"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-[95%] sm:w-[500px] rounded-md bg-white py-6 gap-y-16 flex flex-col items-center justify-center  shadow-md m-auto absolute inset-0 h-fit"
      >
        <div className="flex w-full flex-col gap-y-3 items-center justify-center">
          <Icon className="text-5xl" />
          <p className="font-medium">{`Are you sure you want to make ${role === "member" ? "Admin" : "User"}`}</p>
        </div>

        <div className="flex w-full items-end justify-end gap-x-6 px-4 pt-2 border-t">
          <button onClick={close} className=" font-medium ">
            Cancel
          </button>
          <button
            onClick={changeType}
            className="bg-[#00CCBB] h-10 w-24 flex items-center justify-center gap-x-2 text-white font-medium rounded-md"
          >
            {loading && <LoaderIcon className="animate-spin" />}
            <span>Confirm</span>
          </button>
        </div>
      </div>
    </div>
  );
}
